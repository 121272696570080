

export function routeGuard(role, to) {
    // console.log(role + ':' + to);

    if(role != 'Admin') {
        return false;
    } else {
        return true;
    }
}