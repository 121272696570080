<template>
<footer class="main-footer" :class="{'main-footer-full':!user}">
    <!-- To the right -->
    <div class="float-right d-none d-sm-inline">
      <small>Powered by ITS x Qodeplus</small>
    </div>
    <!-- Default to the left -->
    <strong>Copyright &copy; {{ this.$appyear }} Q+</strong>
</footer>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  name: 'Footer',
  props:{
    version: null,
    appname: null,
  },

  computed: {
    ...mapGetters(['user'])
  },
}
</script>
<style scoped>
.main-footer-full {
    margin-left: 0 !important;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    width: 100% !important;
    margin-left: 0px !important;
}
.sidebar-mini {
    background-color: pink;
}
</style>