<template>
    <div>
        <div class="row">
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-info">
                    <Loader v-if="loading" />
                    <div class="inner">
                    <h2 v-if="!loading">{{months_consumption | toMoney}}</h2>
                    <skeleton-loader-vue v-else class="skeleton mb-3" type="rect" :rounded="true" :width="70" :height="30" animation="fade" />
                    <br/>
                    </div>
                    <div class="icon">
                    <i class="fa fa-tint"></i>
                    </div>
                    <p class="small-box-footer"><b>Water Consumption</b></p>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-success">
                    <Loader v-if="loading" />
                    <div class="inner">
                    <h2 v-if="!loading">{{months_payments | toMoney}}</h2>
                    <skeleton-loader-vue v-else class="skeleton mb-3" type="rect" :rounded="true" :width="70" :height="30" animation="fade" />
                    <br/>
                    </div>
                    <div class="icon">
                    <i class="fa fa-money"></i>
                    </div>
                    <p class="small-box-footer"><b>Payments</b></p>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-warning">
                    <Loader v-if="loading" />
                    <div class="inner">
                    <h2 v-if="!loading">{{months_pending | toMoney}}</h2>
                    <skeleton-loader-vue v-else class="skeleton mb-3" type="rect" :rounded="true" :width="70" :height="30" animation="fade" />
                    <br/>
                    </div>
                    <div class="icon">
                    <i class="fa fa-exclamation-triangle"></i>
                    </div>
                    <p class="small-box-footer"><b>Pending Payments</b></p>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-purple">
                    <Loader v-if="loading" />
                    <div class="inner">
                    <h2 v-if="!loading">{{months_overdue | toMoney}}</h2>
                    <skeleton-loader-vue v-else class="skeleton mb-3" type="rect" :rounded="true" :width="70" :height="30" animation="fade" />
                    <br/>
                    </div>
                    <div class="icon">
                    <i class="fa fa-exclamation-circle"></i>
                    </div>
                    <p class="small-box-footer"><b>Overdue</b></p>
                </div>
            </div>
            <!-- ./col -->
        </div>
        <div class="row">
            <div class="col-lg-12 col-12">
                <div class="card bg-gradient-default">
                    <Loader v-if="!loaded"  />
                    <div class="card-header border-0">
                        <h3 class="card-title mt-2">
                            <i class="fa fa-line-chart mr-1"></i>
                            System Statistics
                        </h3>
                        <div class="card-tools">
                            <button title="Year" type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                {{this.chart_year}}
                            </button>
                            <ul class="dropdown-menu cursor" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 48px, 0px);">
                                <li v-for="year in years" :value="year" :key="year" class="dropdown-item ddown" @click="loadData(year, chart_month)">{{ year }}</li>
                            </ul>
                        </div>
                        <div class="card-tools mr-2">
                            <button title="Month" type="button" class="btn btn-default dropdown-toggle d-none d-lg-block" data-toggle="dropdown" aria-expanded="false">
                                <span v-if="this.chart_month == '01'">January</span>
                                <span v-if="this.chart_month == '02'">February</span>
                                <span v-if="this.chart_month == '03'">March</span>
                                <span v-if="this.chart_month == '04'">April</span>
                                <span v-if="this.chart_month == '05'">May</span>
                                <span v-if="this.chart_month == '06'">June</span>
                                <span v-if="this.chart_month == '07'">July</span>
                                <span v-if="this.chart_month == '08'">August</span>
                                <span v-if="this.chart_month == '09'">September</span>
                                <span v-if="this.chart_month == '10'">October</span>
                                <span v-if="this.chart_month == '11'">November</span>
                                <span v-if="this.chart_month == '12'">December</span>
                            </button>
                            <ul class="dropdown-menu cursor" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 48px, 0px);">
                                <li value="01" class="dropdown-item ddown" @click="loadData(chart_year,'01')">January</li>
                                <li value="02" class="dropdown-item ddown" @click="loadData(chart_year,'02')">February</li>
                                <li value="03" class="dropdown-item ddown" @click="loadData(chart_year,'03')">March</li>
                                <li value="03" class="dropdown-item ddown" @click="loadData(chart_year,'04')">April</li>
                                <li value="03" class="dropdown-item ddown" @click="loadData(chart_year,'05')">May</li>
                                <li value="03" class="dropdown-item ddown" @click="loadData(chart_year,'06')">June</li>
                                <li value="03" class="dropdown-item ddown" @click="loadData(chart_year,'07')">July</li>
                                <li value="03" class="dropdown-item ddown" @click="loadData(chart_year,'08')">August</li>
                                <li value="03" class="dropdown-item ddown" @click="loadData(chart_year,'09')">September</li>
                                <li value="03" class="dropdown-item ddown" @click="loadData(chart_year,'10')">October</li>
                                <li value="03" class="dropdown-item ddown" @click="loadData(chart_year,'11')">November</li>
                                <li value="03" class="dropdown-item ddown" @click="loadData(chart_year,'12')">December</li>
                            </ul>
                        </div>
                        
                    </div>
                    

                    <div v-if="!loaded" class="card-body p-0" style="height: 440px">
                        
                    </div>
                    <div v-else class="card-body">
                        <line-chart v-if="loaded" :chartdata="chartdata" :options="options"></line-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LineChart from './ChartLine'
import Loader from '@/components/Loader'
import axios from 'axios'
import moment from 'moment';
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
    name: 'ChartMain',
    components: {
        LineChart, Loader, VueSkeletonLoader,
    },


    data () {
        return {
            chartdata: null,
            options: null,
            month_list : null,
            loading: true,
            loaded: false,
            chart_year: '',
            chart_month: '',
            data: {},
            months_consumption: '',
            months_payments: '',
            months_pending: '',
            months_overdue: '',
            skeleton: true,
        }
    },

    computed: {
        years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 2019}, (value, index) => 2020 + index)
        },
    },

    methods: {

        loadData(yr, month) {
            this.chart_year = yr;
            this.chart_month = month;
            this.loading = true;
            this.skeleton = true;
            this.loaded = false;

            axios.get('api/dashboard/' + yr + '/' + month).then(response => {
            this.months_consumption = response.data.dashboardConsumption;
            this.months_payments = response.data.dashboardPayments;
            this.months_pending = response.data.dashboardPending;
            this.months_overdue = response.data.dashboardOverdue;
            this.loading = false;
            });

            axios.get("api/dashboard/" + yr).then(response => {
            this.data = response.data,
            this.loaded = true;
            
            this.chartdata = {
                labels: [(yr - 1)], //["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                datasets: [
                    {
                        type: 'bar',
                        label: 'Consumption',
                        data: [
                            response.data.consumptionLastDecember,
                            response.data.consumptionJanuary,
                            response.data.consumptionFebruary,
                            response.data.consumptionMarch,
                            response.data.consumptionApril,
                            response.data.consumptionMay,
                            response.data.consumptionJune,
                            response.data.consumptionJuly,
                            response.data.consumptionAugust,
                            response.data.consumptionSeptember,
                            response.data.consumptionOctober,
                            response.data.consumptionNovember,
                            response.data.consumptionDecember,
                            ],
                        fill: true,
                        borderWidth: 2, 
                        borderColor: '#3498DB',
                        backgroundColor: '#3498DB40',
                    },
                    {
                        type: 'bar',
                        label: 'Payments',
                        data: [
                            response.data.paymentLastDecember,
                            response.data.paymentJanuary,
                            response.data.paymentFebruary,
                            response.data.paymentMarch,
                            response.data.paymentApril,
                            response.data.paymentMay,
                            response.data.paymentJune,
                            response.data.paymentJuly,
                            response.data.paymentAugust,
                            response.data.paymentSeptember,
                            response.data.paymentOctober,
                            response.data.paymentNovember,
                            response.data.paymentDecember,
                            ],
                        fill: true,
                        borderColor: '#24963E',
                        backgroundColor: '#24963E40',
                        borderWidth: 2, 
                    },
                    {
                        type: 'bar',
                        label: 'Pending',
                        data: [
                            response.data.pendingLastDecember,
                            response.data.pendingJanuary,
                            response.data.pendingFebruary,
                            response.data.pendingMarch,
                            response.data.pendingApril,
                            response.data.pendingMay,
                            response.data.pendingJune,
                            response.data.pendingJuly,
                            response.data.pendingAugust,
                            response.data.pendingSeptember,
                            response.data.pendingOctober,
                            response.data.pendingNovember,
                            response.data.pendingDecember,
                            ],
                        fill: true,
                        borderColor: '#E5AD06',
                        backgroundColor: '#E5AD0640',
                        borderWidth: 2, 
                        //barPercentage: 0.4,
                    },
                    {
                        //type: 'bar',
                        label: 'Overdue',
                        data: [
                            response.data.overdueLastDecember,
                            response.data.overdueJanuary,
                            response.data.overdueFebruary,
                            response.data.overdueMarch,
                            response.data.overdueApril,
                            response.data.overdueMay,
                            response.data.overdueJune,
                            response.data.overdueJuly,
                            response.data.overdueAugust,
                            response.data.overdueSeptember,
                            response.data.overdueOctober,
                            response.data.overdueNovember,
                            response.data.overdueDecember,
                            ],
                        fill: true,
                        borderColor: '#A57DDD40',
                        pointBackgroundColor: '#DC3545',
                        backgroundColor: '#A57DFC40',
                        borderWidth: 2,
                        pointBackgroundColor: '#A57DFC',
                        pointBorderColor: '#fff',
                        pointHoverBorderColor: '#fff',
                        pointHoverBorderWidth: 1,
                        pointHoverRadius: 8,
                        pointBorderWidth: 1,
                        pointRadius: 5,
                    },
                ]
            };
            this.options = {
                scales: {
                yAxes: [{
                    ticks: {
                    beginAtZero: true
                    },
                    gridLines: {
                    display: true,
                    }
                }],
                xAxes: [ {
                    gridLines: {
                    display: false
                    },
                }]
                },
                legend: {
                display: true
                },
                responsive: true,
                maintainAspectRatio: false
            };

            this.skeleton = false

            this.getMonths(yr);

            }).catch(err => {
                console.log(err);
            });

        },

        getMonths(yr) {   
            const current = new Date();
            if(yr < current.getFullYear())
            {
                this.chartdata.labels.push("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December")
            } else {
                Array.from({length: current.getMonth() + 1}, (e, i) => {
                    this.chartdata.labels.push(moment(String(i + 1)).format('MMMM'))
                })
            }
        },


    },

    created () {
        const current = new Date();
        const current_year =  current.getFullYear();
        const current_month = moment(String(current)).format('MM');
        this.loadData(current_year, current_month); 
    }
}
</script>
<style scoped>
.cursor {
  cursor: pointer;
}
</style>