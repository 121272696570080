<template>
    <div class="fab-container">
        <div class="fab shadow">
            <div class="fab-content">
                <i class="fa fa-stack-exchange"></i>
            </div>
        </div>
        <div class="sub-button shadow">
            <button @click="showMailModal()" type="button" class="btn btn-link"><i class="fa fa-envelope"></i></button>
        </div>
        <div class="sub-button shadow">
            <button @click="showMobileModal()" type="button" class="btn btn-link"><i class="fa fa-phone"></i></button>
        </div>
        <div class="sub-button shadow">
            <a href="https://integratetechsolutions.qodeplus.com" target="_blank"><i class="fa fa-globe"></i></a>
        </div>

        <!-- Mobile Modal -->
        <div class="modal fade" data-backdrop="static" id="mobilemodal" tabindex="-1" role="dialog" aria-labelledby="mobileModalLabel1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header bg-orange">
                <h1 class="card-title modal-title"><b>Call Us</b></h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                    <div class="card-body">
                        <div class="row">
                            <p>We are happy to assist you!</p>
                            <div class="col-sm-12 text-center">
                                <h1>GLOBE: +639171477768</h1>
                                <h1>SMART: +639104430020</h1>
                            </div>
                        </div>
                    </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Mail Modal -->
        <div class="modal fade" data-backdrop="static" id="mailmodal" tabindex="-1" role="dialog" aria-labelledby="mailModalLabel1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header bg-orange">
                <h1 class="card-title modal-title"><b>Contact Us</b></h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
                <form @submit.prevent="sendMail()" @keydown="form.onKeydown($event)" novalidate>

                    <div class="modal-body">
                        <div class="card-body">
                            
                            <div class="row">
                                <p>We would like to hear from you!</p>
                                <div class="col-sm-12">
                                    <div class="form-group" :class="{'has-error':$v.form.name.$error,'has-success':!$v.form.name.$invalid }">
                                        <label for="inputNname">Name</label><span class="text-danger">*</span>
                                        <input @keydown.enter="focusNext" type="text"  v-model.lazy="$v.form.name.$model" class="form-control" id="inputName" aria-describedby="inputName" :class="{'is-invalid':$v.form.name.$error,'is-valid':!$v.form.name.$invalid }" placeholder="Name" autocomplete="off" />
                                        <div class="invalid-feedback">
                                            <span class="help-block" v-if="!$v.form.name.required"><i class="fa fa-exclamation-circle"></i> Name is required.</span>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{'has-error':$v.form.email.$error,'has-success':!$v.form.email.$invalid }">
                                        <label for="inputEmail">Email Address</label><span class="text-danger">*</span>
                                        <input @keydown.enter="focusNext" type="text"  v-model.lazy="$v.form.email.$model" class="form-control" id="inputEmail" aria-describedby="inputEmail" :class="{'is-invalid':$v.form.email.$error,'is-valid':!$v.form.email.$invalid }" placeholder="Email" autocomplete="off" />
                                        <div class="invalid-feedback">
                                            <span class="help-block" v-if="!$v.form.email.required"><i class="fa fa-exclamation-circle"></i> Email is required.</span>
                                            <span class="help-block" v-if="!$v.form.email.email"><i class="fa fa-exclamation-circle"></i> Email must be valid.</span>
                                        </div>

                                        <small class="text-success">
                                            <i class="fa fa-exclamation-circle"></i> We will be responding to your email address please ensure that this is correct.
                                        </small>
                    
                                    </div>

                                    <div class="form-group" :class="{'has-error':$v.form.message.$error,'has-success':!$v.form.message.$invalid }">
                                        <label for="inputEmail">Message / Inquiry / Concern</label><span class="text-danger">*</span>
                                        <textarea rows="7" @keydown.enter="focusNext" type="text"  v-model.lazy="$v.form.message.$model" class="form-control" id="inputMessage" aria-describedby="inputMessage" :class="{'is-invalid':$v.form.message.$error,'is-valid':!$v.form.message.$invalid }" placeholder="Message" autocomplete="off" />
                                        <div class="invalid-feedback">
                                            <span class="help-block" v-if="!$v.form.message.required"><i class="fa fa-exclamation-circle"></i> Message is required.</span>
                                            <span class="help-block" v-if="!$v.form.message.minLength"><i class="fa fa-exclamation-circle"></i> Message must have at least {{$v.form.message.$params.minLength.min}} characters.</span>
                                            <span class="help-block" v-if="!$v.form.message.maxLength"><i class="fa fa-exclamation-circle"></i> Message must not exeed to {{$v.form.message.$params.maxLength.max}} characters.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div class="card-footer">
                        <div class="row col-12 p-0 m-0">
                            <div class="col-8 p-0">
                            
                            </div>
                            <div class="text-right col-12 p-0">
                                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>&nbsp;
                                <button type="submit" :disabled="$v.$invalid" class="btn btn-info" ><i class="fa fa-paper-plane"></i> Send</button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
          </div>
        </div>



    </div>
</template>

<script>

    import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
    import Form from 'vform'
    import axios from 'axios'

    export default {
        name: 'FloatingButtons',

         data() {
            return {
            loading: false,
            form: new Form({
                name: '',
                email: '',
                message: '',
            })
            }
        },

        validations: {
            form: {
                name: { required },
                email: { required,  email },
                message: { required,  minLength: minLength(2),  maxLength: maxLength(1000) },
            }
        },

        methods: {
            showMobileModal() {
                $('#mobilemodal').modal('show');
            },

            showMailModal() {
                $('#mailmodal').modal('show');
            },

            async sendMail() {
                this.$Progress.start();
                this.loading = true;
                await this.form.post('api/contactus').then(()=>{
                    this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your message is sent. We`ll get back to you shortly.',
                    })
                    this.form.reset();
                    this.form.clear();
                    $('#mailmodal').modal('hide');
                    this.$Progress.finish();
                    this.loading = false;
                }).catch(()=>{
                    this.$swal.fire({
                    icon: 'error',
                    title: 'Message sending errror'
                    });
                    this.$Progress.fail();
                    this.loading = false;
                });
            },

        },
    }
</script>

<style scoped>
.fab-container {
	 display: flex;
	 flex-direction: column;
	 justify-content: flex-end;
	 align-items: center;
	 user-select: none;
	 position: fixed;
	 bottom: 45px;
	 right: 30px;
     z-index: 99999;
}
 .fab-container:hover {
	 height: 100%;
}
 .fab-container:hover .sub-button:nth-child(2) {
	 transform: translateY(-80px);
}
 .fab-container:hover .sub-button:nth-child(3) {
	 transform: translateY(-140px);
}
 .fab-container:hover .sub-button:nth-child(4) {
	 transform: translateY(-200px);
}
 .fab-container:hover .sub-button:nth-child(5) {
	 transform: translateY(-260px);
}
 .fab-container:hover .sub-button:nth-child(6) {
	 transform: translateY(-320px);
}
 .fab-container .fab {
	 position: relative;
	 height: 70px;
	 width: 70px;
	 background-color: #4ba2ff;
	 border-radius: 50%;
	 z-index: 2;
}
 .fab-container .fab::before {
	 content: " ";
	 position: absolute;
	 bottom: 0;
	 right: 0;
	 height: 35px;
	 width: 35px;
	 background-color: inherit;
	 border-radius: 0 0 10px 0;
	 z-index: -1;
}
 .fab-container .fab .fab-content {
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 height: 100%;
	 width: 100%;
	 border-radius: 50%;
}
 .fab-container .fab .fab-content .fa {
	 color: white;
	 font-size: 48px;
}
 .fab-container .sub-button {
	 position: absolute;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 bottom: 10px;
	 right: 10px;
	 height: 50px;
	 width: 50px;
	 background-color: #4ba2ff;
	 border-radius: 50%;
	 transition: all 0.3s ease;
}
 .fab-container .sub-button:hover {
	 cursor: pointer;
}
 .fab-container .sub-button .fa {
	 color: white;
	 padding-top: 6px;
}
 
</style>