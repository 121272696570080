<template>
  <!-- Main Sidebar Container -->
  <aside v-if="user" class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <router-link to="/dashboard" class="brand-link">
      <img :src="this.$client_picture_baseurl + 'logo.png'" alt="" width="35px" height="35px" class="brand-image img-circle elevation-3"
           style="opacity: 1.0">
      <span class="brand-text font-weight-strong">
        {{ this.client.appname }}<skeleton-loader-vue v-show="skeleton" type="rect" :rounded="true" :width="180" :height="28" animation="fade" />
      </span>
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img v-if="!skeleton" :src="this.$profile_picture_baseurl + photo" width="35px" height="35px" class="img-circle elevation-2" alt="User Image">
          <skeleton-loader-vue v-else type="circle" :rounded="true" :width="34" :height="35" animation="fade" />
        </div>
        <div class="info">
          <router-link to="/profile" class="d-block">Hi, {{user.name}}</router-link>
          <small class="text-status"><i class="fa fa-circle"></i> {{user.role}}</small>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview"  data-accordion="false">
          <li class="nav-item">
            <router-link to="/dashboard" class="nav-link">
              <i class="nav-icon fa fa-tachometer"></i>
              <p>Dashboard</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/consumers" class="nav-link">
              <i class="fa fa-vcard nav-icon"></i>
              <p>Consumers</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/fees/0" class="nav-link">
              <i class="fa fa-money nav-icon"></i>
              <p>Other Fees</p>
            </router-link>
          </li>
          <li v-show="user.role == 'Admin' || user.role == 'Supervisor'" class="nav-item">
            <router-link to="/booklet" class="nav-link">
              <i class="fa fa-book nav-icon"></i>
              <p>Booklet</p>
            </router-link>
          </li>
          <li v-show="user.role == 'Admin' || user.role == 'Supervisor' || user.role == 'Cashier'" class="nav-item">
            <router-link to="/settings" class="nav-link">
              <i class="fa fa-cog nav-icon"></i>
              <p>Settings</p>
            </router-link>
          </li>
         
          <li class="nav-item">
            <router-link to="/consumption" class="nav-link">
              <i class="fa fa-line-chart nav-icon"></i>
              <p>Consumption Book</p>
            </router-link>
          </li>
          
          <li v-show="user.role == 'Admin' || user.role == 'Supervisor' || user.role == 'Cashier'" class="nav-item">
            <router-link to="/financial" class="nav-link">
              <i class="fa fa-bar-chart nav-icon"></i>
              <p>Collection Report</p>
            </router-link>
          </li>
          
          <li class="nav-item">
            <router-link to="/profile" class="nav-link">
              <i class="fa fa-user nav-icon"></i>
              <p>My Profile</p>
            </router-link>
          </li>
          
          <li v-show="user.role == 'Admin' || user.role == 'Supervisor'" class="nav-item"> <!--  -->
            <router-link to="/employees" class="nav-link">
              <i class="fa fa-users nav-icon"></i>
              <p>Employees</p>
            </router-link>
          </li>
  
          <li class="nav-item">
            <router-link to="/about" class="nav-link">
              <i class="nav-icon fa fa-info-circle"></i>
              <p>About</p>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>

</template>

<script>
import axios from 'axios';
import {mapGetters} from 'vuex'
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'Leftnav',

  components: {
    VueSkeletonLoader,
  },

  props:{
    avatar: null,
    logo: null,
    appname: null,
  },

  data() {
    return {
      client: {},
      photo: '',
      skeleton: true,
    }
  },

  computed: {
    ...mapGetters(['user']),
  },

  watch: {
    avatar: function () {
      // change value when the avatar changes
      this.photo = localStorage.getItem("avatar")
    },
  },

  methods: {
    
  },

  async mounted () {
    this.skeleton = true;
    await axios.get('api/client').then(({ data }) => (
      this.client = data, 
      this.skeleton = false,
      this.photo = localStorage.getItem("avatar")
    ));
    
    this.skeleton = false;
  }

}
</script>

<style scoped>
.skeleton {
  width: 100% !important;
}
</style>